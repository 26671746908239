import { default as indexUbkSq4askOMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/index.vue?macro=true";
import { default as resultsDKqG6QYuuqMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/results.vue?macro=true";
import { default as index4igdOzN3C3Meta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/index.vue?macro=true";
import { default as noneh8XCz9Ae8oMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/none.vue?macro=true";
import { default as profileMVtJ53bXayMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/profile.vue?macro=true";
import { default as image3DleFDSuVBMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/answer/[answerId]/image.vue?macro=true";
import { default as index9JJdomvbZCMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/index.vue?macro=true";
import { default as aggregategJfPVRWo5aMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue?macro=true";
import { default as indexiXJz6RudIxMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/index.vue?macro=true";
import { default as profilex1o2kZlMDwMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/profile.vue?macro=true";
import { default as resultsaJfc96uHLFMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/results.vue?macro=true";
import { default as _91roundId_93HIgE28NXgFMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue?macro=true";
import { default as createQsHViqT8m4Meta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue?macro=true";
import { default as _91userId_931r1SUoMOf5Meta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue?macro=true";
import { default as indexT5UQihfUU2Meta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue?macro=true";
import { default as createB0veamv66TMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/create.vue?macro=true";
import { default as indexq9u1LmbJ92Meta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/index.vue?macro=true";
import { default as login2JQ1VYThUCMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/login.vue?macro=true";
import { default as registeraaxlU31oFBMeta } from "/codebuild/output/src1175223133/src/sense-us.app/pages/register.vue?macro=true";
export default [
  {
    name: indexUbkSq4askOMeta?.name ?? "projectId-roundId___en",
    path: indexUbkSq4askOMeta?.path ?? "/:projectId()/:roundId()",
    meta: indexUbkSq4askOMeta || {},
    alias: indexUbkSq4askOMeta?.alias || [],
    redirect: indexUbkSq4askOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUbkSq4askOMeta?.name ?? "projectId-roundId___nl",
    path: indexUbkSq4askOMeta?.path ?? "/nl/:projectId()/:roundId()",
    meta: indexUbkSq4askOMeta || {},
    alias: indexUbkSq4askOMeta?.alias || [],
    redirect: indexUbkSq4askOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUbkSq4askOMeta?.name ?? "projectId-roundId___de",
    path: indexUbkSq4askOMeta?.path ?? "/de/:projectId()/:roundId()",
    meta: indexUbkSq4askOMeta || {},
    alias: indexUbkSq4askOMeta?.alias || [],
    redirect: indexUbkSq4askOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUbkSq4askOMeta?.name ?? "projectId-roundId___es",
    path: indexUbkSq4askOMeta?.path ?? "/es/:projectId()/:roundId()",
    meta: indexUbkSq4askOMeta || {},
    alias: indexUbkSq4askOMeta?.alias || [],
    redirect: indexUbkSq4askOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/index.vue").then(m => m.default || m)
  },
  {
    name: resultsDKqG6QYuuqMeta?.name ?? "projectId-roundId-results___en",
    path: resultsDKqG6QYuuqMeta?.path ?? "/:projectId()/:roundId()/results",
    meta: resultsDKqG6QYuuqMeta || {},
    alias: resultsDKqG6QYuuqMeta?.alias || [],
    redirect: resultsDKqG6QYuuqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsDKqG6QYuuqMeta?.name ?? "projectId-roundId-results___nl",
    path: resultsDKqG6QYuuqMeta?.path ?? "/nl/:projectId()/:roundId()/results",
    meta: resultsDKqG6QYuuqMeta || {},
    alias: resultsDKqG6QYuuqMeta?.alias || [],
    redirect: resultsDKqG6QYuuqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsDKqG6QYuuqMeta?.name ?? "projectId-roundId-results___de",
    path: resultsDKqG6QYuuqMeta?.path ?? "/de/:projectId()/:roundId()/results",
    meta: resultsDKqG6QYuuqMeta || {},
    alias: resultsDKqG6QYuuqMeta?.alias || [],
    redirect: resultsDKqG6QYuuqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsDKqG6QYuuqMeta?.name ?? "projectId-roundId-results___es",
    path: resultsDKqG6QYuuqMeta?.path ?? "/es/:projectId()/:roundId()/results",
    meta: resultsDKqG6QYuuqMeta || {},
    alias: resultsDKqG6QYuuqMeta?.alias || [],
    redirect: resultsDKqG6QYuuqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/[roundId]/results.vue").then(m => m.default || m)
  },
  {
    name: index4igdOzN3C3Meta?.name ?? "projectId___en",
    path: index4igdOzN3C3Meta?.path ?? "/:projectId()",
    meta: index4igdOzN3C3Meta || {},
    alias: index4igdOzN3C3Meta?.alias || [],
    redirect: index4igdOzN3C3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: index4igdOzN3C3Meta?.name ?? "projectId___nl",
    path: index4igdOzN3C3Meta?.path ?? "/nl/:projectId()",
    meta: index4igdOzN3C3Meta || {},
    alias: index4igdOzN3C3Meta?.alias || [],
    redirect: index4igdOzN3C3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: index4igdOzN3C3Meta?.name ?? "projectId___de",
    path: index4igdOzN3C3Meta?.path ?? "/de/:projectId()",
    meta: index4igdOzN3C3Meta || {},
    alias: index4igdOzN3C3Meta?.alias || [],
    redirect: index4igdOzN3C3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: index4igdOzN3C3Meta?.name ?? "projectId___es",
    path: index4igdOzN3C3Meta?.path ?? "/es/:projectId()",
    meta: index4igdOzN3C3Meta || {},
    alias: index4igdOzN3C3Meta?.alias || [],
    redirect: index4igdOzN3C3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: noneh8XCz9Ae8oMeta?.name ?? "projectId-none___en",
    path: noneh8XCz9Ae8oMeta?.path ?? "/:projectId()/none",
    meta: noneh8XCz9Ae8oMeta || {},
    alias: noneh8XCz9Ae8oMeta?.alias || [],
    redirect: noneh8XCz9Ae8oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: noneh8XCz9Ae8oMeta?.name ?? "projectId-none___nl",
    path: noneh8XCz9Ae8oMeta?.path ?? "/nl/:projectId()/none",
    meta: noneh8XCz9Ae8oMeta || {},
    alias: noneh8XCz9Ae8oMeta?.alias || [],
    redirect: noneh8XCz9Ae8oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: noneh8XCz9Ae8oMeta?.name ?? "projectId-none___de",
    path: noneh8XCz9Ae8oMeta?.path ?? "/de/:projectId()/none",
    meta: noneh8XCz9Ae8oMeta || {},
    alias: noneh8XCz9Ae8oMeta?.alias || [],
    redirect: noneh8XCz9Ae8oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: noneh8XCz9Ae8oMeta?.name ?? "projectId-none___es",
    path: noneh8XCz9Ae8oMeta?.path ?? "/es/:projectId()/none",
    meta: noneh8XCz9Ae8oMeta || {},
    alias: noneh8XCz9Ae8oMeta?.alias || [],
    redirect: noneh8XCz9Ae8oMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/none.vue").then(m => m.default || m)
  },
  {
    name: profileMVtJ53bXayMeta?.name ?? "projectId-profile___en",
    path: profileMVtJ53bXayMeta?.path ?? "/:projectId()/profile",
    meta: profileMVtJ53bXayMeta || {},
    alias: profileMVtJ53bXayMeta?.alias || [],
    redirect: profileMVtJ53bXayMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMVtJ53bXayMeta?.name ?? "projectId-profile___nl",
    path: profileMVtJ53bXayMeta?.path ?? "/nl/:projectId()/profile",
    meta: profileMVtJ53bXayMeta || {},
    alias: profileMVtJ53bXayMeta?.alias || [],
    redirect: profileMVtJ53bXayMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMVtJ53bXayMeta?.name ?? "projectId-profile___de",
    path: profileMVtJ53bXayMeta?.path ?? "/de/:projectId()/profile",
    meta: profileMVtJ53bXayMeta || {},
    alias: profileMVtJ53bXayMeta?.alias || [],
    redirect: profileMVtJ53bXayMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profileMVtJ53bXayMeta?.name ?? "projectId-profile___es",
    path: profileMVtJ53bXayMeta?.path ?? "/es/:projectId()/profile",
    meta: profileMVtJ53bXayMeta || {},
    alias: profileMVtJ53bXayMeta?.alias || [],
    redirect: profileMVtJ53bXayMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: image3DleFDSuVBMeta?.name ?? "admin-answer-answerId-image___en",
    path: image3DleFDSuVBMeta?.path ?? "/admin/answer/:answerId()/image",
    meta: image3DleFDSuVBMeta || {},
    alias: image3DleFDSuVBMeta?.alias || [],
    redirect: image3DleFDSuVBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: image3DleFDSuVBMeta?.name ?? "admin-answer-answerId-image___nl",
    path: image3DleFDSuVBMeta?.path ?? "/nl/admin/answer/:answerId()/image",
    meta: image3DleFDSuVBMeta || {},
    alias: image3DleFDSuVBMeta?.alias || [],
    redirect: image3DleFDSuVBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: image3DleFDSuVBMeta?.name ?? "admin-answer-answerId-image___de",
    path: image3DleFDSuVBMeta?.path ?? "/de/admin/answer/:answerId()/image",
    meta: image3DleFDSuVBMeta || {},
    alias: image3DleFDSuVBMeta?.alias || [],
    redirect: image3DleFDSuVBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: image3DleFDSuVBMeta?.name ?? "admin-answer-answerId-image___es",
    path: image3DleFDSuVBMeta?.path ?? "/es/admin/answer/:answerId()/image",
    meta: image3DleFDSuVBMeta || {},
    alias: image3DleFDSuVBMeta?.alias || [],
    redirect: image3DleFDSuVBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/answer/[answerId]/image.vue").then(m => m.default || m)
  },
  {
    name: index9JJdomvbZCMeta?.name ?? "admin___en",
    path: index9JJdomvbZCMeta?.path ?? "/admin",
    meta: index9JJdomvbZCMeta || {},
    alias: index9JJdomvbZCMeta?.alias || [],
    redirect: index9JJdomvbZCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: index9JJdomvbZCMeta?.name ?? "admin___nl",
    path: index9JJdomvbZCMeta?.path ?? "/nl/admin",
    meta: index9JJdomvbZCMeta || {},
    alias: index9JJdomvbZCMeta?.alias || [],
    redirect: index9JJdomvbZCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: index9JJdomvbZCMeta?.name ?? "admin___de",
    path: index9JJdomvbZCMeta?.path ?? "/de/admin",
    meta: index9JJdomvbZCMeta || {},
    alias: index9JJdomvbZCMeta?.alias || [],
    redirect: index9JJdomvbZCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: index9JJdomvbZCMeta?.name ?? "admin___es",
    path: index9JJdomvbZCMeta?.path ?? "/es/admin",
    meta: index9JJdomvbZCMeta || {},
    alias: index9JJdomvbZCMeta?.alias || [],
    redirect: index9JJdomvbZCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: aggregategJfPVRWo5aMeta?.name ?? "admin-project-projectId-aggregate___en",
    path: aggregategJfPVRWo5aMeta?.path ?? "/admin/project/:projectId()/aggregate",
    meta: aggregategJfPVRWo5aMeta || {},
    alias: aggregategJfPVRWo5aMeta?.alias || [],
    redirect: aggregategJfPVRWo5aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregategJfPVRWo5aMeta?.name ?? "admin-project-projectId-aggregate___nl",
    path: aggregategJfPVRWo5aMeta?.path ?? "/nl/admin/project/:projectId()/aggregate",
    meta: aggregategJfPVRWo5aMeta || {},
    alias: aggregategJfPVRWo5aMeta?.alias || [],
    redirect: aggregategJfPVRWo5aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregategJfPVRWo5aMeta?.name ?? "admin-project-projectId-aggregate___de",
    path: aggregategJfPVRWo5aMeta?.path ?? "/de/admin/project/:projectId()/aggregate",
    meta: aggregategJfPVRWo5aMeta || {},
    alias: aggregategJfPVRWo5aMeta?.alias || [],
    redirect: aggregategJfPVRWo5aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: aggregategJfPVRWo5aMeta?.name ?? "admin-project-projectId-aggregate___es",
    path: aggregategJfPVRWo5aMeta?.path ?? "/es/admin/project/:projectId()/aggregate",
    meta: aggregategJfPVRWo5aMeta || {},
    alias: aggregategJfPVRWo5aMeta?.alias || [],
    redirect: aggregategJfPVRWo5aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/aggregate.vue").then(m => m.default || m)
  },
  {
    name: indexiXJz6RudIxMeta?.name ?? "admin-project-projectId___en",
    path: indexiXJz6RudIxMeta?.path ?? "/admin/project/:projectId()",
    meta: indexiXJz6RudIxMeta || {},
    alias: indexiXJz6RudIxMeta?.alias || [],
    redirect: indexiXJz6RudIxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiXJz6RudIxMeta?.name ?? "admin-project-projectId___nl",
    path: indexiXJz6RudIxMeta?.path ?? "/nl/admin/project/:projectId()",
    meta: indexiXJz6RudIxMeta || {},
    alias: indexiXJz6RudIxMeta?.alias || [],
    redirect: indexiXJz6RudIxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiXJz6RudIxMeta?.name ?? "admin-project-projectId___de",
    path: indexiXJz6RudIxMeta?.path ?? "/de/admin/project/:projectId()",
    meta: indexiXJz6RudIxMeta || {},
    alias: indexiXJz6RudIxMeta?.alias || [],
    redirect: indexiXJz6RudIxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiXJz6RudIxMeta?.name ?? "admin-project-projectId___es",
    path: indexiXJz6RudIxMeta?.path ?? "/es/admin/project/:projectId()",
    meta: indexiXJz6RudIxMeta || {},
    alias: indexiXJz6RudIxMeta?.alias || [],
    redirect: indexiXJz6RudIxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/index.vue").then(m => m.default || m)
  },
  {
    name: profilex1o2kZlMDwMeta?.name ?? "admin-project-projectId-profile___en",
    path: profilex1o2kZlMDwMeta?.path ?? "/admin/project/:projectId()/profile",
    meta: profilex1o2kZlMDwMeta || {},
    alias: profilex1o2kZlMDwMeta?.alias || [],
    redirect: profilex1o2kZlMDwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profilex1o2kZlMDwMeta?.name ?? "admin-project-projectId-profile___nl",
    path: profilex1o2kZlMDwMeta?.path ?? "/nl/admin/project/:projectId()/profile",
    meta: profilex1o2kZlMDwMeta || {},
    alias: profilex1o2kZlMDwMeta?.alias || [],
    redirect: profilex1o2kZlMDwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profilex1o2kZlMDwMeta?.name ?? "admin-project-projectId-profile___de",
    path: profilex1o2kZlMDwMeta?.path ?? "/de/admin/project/:projectId()/profile",
    meta: profilex1o2kZlMDwMeta || {},
    alias: profilex1o2kZlMDwMeta?.alias || [],
    redirect: profilex1o2kZlMDwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: profilex1o2kZlMDwMeta?.name ?? "admin-project-projectId-profile___es",
    path: profilex1o2kZlMDwMeta?.path ?? "/es/admin/project/:projectId()/profile",
    meta: profilex1o2kZlMDwMeta || {},
    alias: profilex1o2kZlMDwMeta?.alias || [],
    redirect: profilex1o2kZlMDwMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/profile.vue").then(m => m.default || m)
  },
  {
    name: resultsaJfc96uHLFMeta?.name ?? "admin-project-projectId-results___en",
    path: resultsaJfc96uHLFMeta?.path ?? "/admin/project/:projectId()/results",
    meta: resultsaJfc96uHLFMeta || {},
    alias: resultsaJfc96uHLFMeta?.alias || [],
    redirect: resultsaJfc96uHLFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsaJfc96uHLFMeta?.name ?? "admin-project-projectId-results___nl",
    path: resultsaJfc96uHLFMeta?.path ?? "/nl/admin/project/:projectId()/results",
    meta: resultsaJfc96uHLFMeta || {},
    alias: resultsaJfc96uHLFMeta?.alias || [],
    redirect: resultsaJfc96uHLFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsaJfc96uHLFMeta?.name ?? "admin-project-projectId-results___de",
    path: resultsaJfc96uHLFMeta?.path ?? "/de/admin/project/:projectId()/results",
    meta: resultsaJfc96uHLFMeta || {},
    alias: resultsaJfc96uHLFMeta?.alias || [],
    redirect: resultsaJfc96uHLFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: resultsaJfc96uHLFMeta?.name ?? "admin-project-projectId-results___es",
    path: resultsaJfc96uHLFMeta?.path ?? "/es/admin/project/:projectId()/results",
    meta: resultsaJfc96uHLFMeta || {},
    alias: resultsaJfc96uHLFMeta?.alias || [],
    redirect: resultsaJfc96uHLFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/results.vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93HIgE28NXgFMeta?.name ?? "admin-project-projectId-round-roundId___en",
    path: _91roundId_93HIgE28NXgFMeta?.path ?? "/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93HIgE28NXgFMeta || {},
    alias: _91roundId_93HIgE28NXgFMeta?.alias || [],
    redirect: _91roundId_93HIgE28NXgFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93HIgE28NXgFMeta?.name ?? "admin-project-projectId-round-roundId___nl",
    path: _91roundId_93HIgE28NXgFMeta?.path ?? "/nl/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93HIgE28NXgFMeta || {},
    alias: _91roundId_93HIgE28NXgFMeta?.alias || [],
    redirect: _91roundId_93HIgE28NXgFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93HIgE28NXgFMeta?.name ?? "admin-project-projectId-round-roundId___de",
    path: _91roundId_93HIgE28NXgFMeta?.path ?? "/de/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93HIgE28NXgFMeta || {},
    alias: _91roundId_93HIgE28NXgFMeta?.alias || [],
    redirect: _91roundId_93HIgE28NXgFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: _91roundId_93HIgE28NXgFMeta?.name ?? "admin-project-projectId-round-roundId___es",
    path: _91roundId_93HIgE28NXgFMeta?.path ?? "/es/admin/project/:projectId()/round/:roundId()",
    meta: _91roundId_93HIgE28NXgFMeta || {},
    alias: _91roundId_93HIgE28NXgFMeta?.alias || [],
    redirect: _91roundId_93HIgE28NXgFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/[roundId].vue").then(m => m.default || m)
  },
  {
    name: createQsHViqT8m4Meta?.name ?? "admin-project-projectId-round-create___en",
    path: createQsHViqT8m4Meta?.path ?? "/admin/project/:projectId()/round/create",
    meta: createQsHViqT8m4Meta || {},
    alias: createQsHViqT8m4Meta?.alias || [],
    redirect: createQsHViqT8m4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createQsHViqT8m4Meta?.name ?? "admin-project-projectId-round-create___nl",
    path: createQsHViqT8m4Meta?.path ?? "/nl/admin/project/:projectId()/round/create",
    meta: createQsHViqT8m4Meta || {},
    alias: createQsHViqT8m4Meta?.alias || [],
    redirect: createQsHViqT8m4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createQsHViqT8m4Meta?.name ?? "admin-project-projectId-round-create___de",
    path: createQsHViqT8m4Meta?.path ?? "/de/admin/project/:projectId()/round/create",
    meta: createQsHViqT8m4Meta || {},
    alias: createQsHViqT8m4Meta?.alias || [],
    redirect: createQsHViqT8m4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: createQsHViqT8m4Meta?.name ?? "admin-project-projectId-round-create___es",
    path: createQsHViqT8m4Meta?.path ?? "/es/admin/project/:projectId()/round/create",
    meta: createQsHViqT8m4Meta || {},
    alias: createQsHViqT8m4Meta?.alias || [],
    redirect: createQsHViqT8m4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/round/create.vue").then(m => m.default || m)
  },
  {
    name: _91userId_931r1SUoMOf5Meta?.name ?? "admin-project-projectId-users-userId___en",
    path: _91userId_931r1SUoMOf5Meta?.path ?? "/admin/project/:projectId()/users/:userId()",
    meta: _91userId_931r1SUoMOf5Meta || {},
    alias: _91userId_931r1SUoMOf5Meta?.alias || [],
    redirect: _91userId_931r1SUoMOf5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_931r1SUoMOf5Meta?.name ?? "admin-project-projectId-users-userId___nl",
    path: _91userId_931r1SUoMOf5Meta?.path ?? "/nl/admin/project/:projectId()/users/:userId()",
    meta: _91userId_931r1SUoMOf5Meta || {},
    alias: _91userId_931r1SUoMOf5Meta?.alias || [],
    redirect: _91userId_931r1SUoMOf5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_931r1SUoMOf5Meta?.name ?? "admin-project-projectId-users-userId___de",
    path: _91userId_931r1SUoMOf5Meta?.path ?? "/de/admin/project/:projectId()/users/:userId()",
    meta: _91userId_931r1SUoMOf5Meta || {},
    alias: _91userId_931r1SUoMOf5Meta?.alias || [],
    redirect: _91userId_931r1SUoMOf5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: _91userId_931r1SUoMOf5Meta?.name ?? "admin-project-projectId-users-userId___es",
    path: _91userId_931r1SUoMOf5Meta?.path ?? "/es/admin/project/:projectId()/users/:userId()",
    meta: _91userId_931r1SUoMOf5Meta || {},
    alias: _91userId_931r1SUoMOf5Meta?.alias || [],
    redirect: _91userId_931r1SUoMOf5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: indexT5UQihfUU2Meta?.name ?? "admin-project-projectId-users___en",
    path: indexT5UQihfUU2Meta?.path ?? "/admin/project/:projectId()/users",
    meta: indexT5UQihfUU2Meta || {},
    alias: indexT5UQihfUU2Meta?.alias || [],
    redirect: indexT5UQihfUU2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexT5UQihfUU2Meta?.name ?? "admin-project-projectId-users___nl",
    path: indexT5UQihfUU2Meta?.path ?? "/nl/admin/project/:projectId()/users",
    meta: indexT5UQihfUU2Meta || {},
    alias: indexT5UQihfUU2Meta?.alias || [],
    redirect: indexT5UQihfUU2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexT5UQihfUU2Meta?.name ?? "admin-project-projectId-users___de",
    path: indexT5UQihfUU2Meta?.path ?? "/de/admin/project/:projectId()/users",
    meta: indexT5UQihfUU2Meta || {},
    alias: indexT5UQihfUU2Meta?.alias || [],
    redirect: indexT5UQihfUU2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: indexT5UQihfUU2Meta?.name ?? "admin-project-projectId-users___es",
    path: indexT5UQihfUU2Meta?.path ?? "/es/admin/project/:projectId()/users",
    meta: indexT5UQihfUU2Meta || {},
    alias: indexT5UQihfUU2Meta?.alias || [],
    redirect: indexT5UQihfUU2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/[projectId]/users/index.vue").then(m => m.default || m)
  },
  {
    name: createB0veamv66TMeta?.name ?? "admin-project-create___en",
    path: createB0veamv66TMeta?.path ?? "/admin/project/create",
    meta: createB0veamv66TMeta || {},
    alias: createB0veamv66TMeta?.alias || [],
    redirect: createB0veamv66TMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createB0veamv66TMeta?.name ?? "admin-project-create___nl",
    path: createB0veamv66TMeta?.path ?? "/nl/admin/project/create",
    meta: createB0veamv66TMeta || {},
    alias: createB0veamv66TMeta?.alias || [],
    redirect: createB0veamv66TMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createB0veamv66TMeta?.name ?? "admin-project-create___de",
    path: createB0veamv66TMeta?.path ?? "/de/admin/project/create",
    meta: createB0veamv66TMeta || {},
    alias: createB0veamv66TMeta?.alias || [],
    redirect: createB0veamv66TMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: createB0veamv66TMeta?.name ?? "admin-project-create___es",
    path: createB0veamv66TMeta?.path ?? "/es/admin/project/create",
    meta: createB0veamv66TMeta || {},
    alias: createB0veamv66TMeta?.alias || [],
    redirect: createB0veamv66TMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/admin/project/create.vue").then(m => m.default || m)
  },
  {
    name: indexq9u1LmbJ92Meta?.name ?? "index___en",
    path: indexq9u1LmbJ92Meta?.path ?? "/",
    meta: indexq9u1LmbJ92Meta || {},
    alias: indexq9u1LmbJ92Meta?.alias || [],
    redirect: indexq9u1LmbJ92Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexq9u1LmbJ92Meta?.name ?? "index___nl",
    path: indexq9u1LmbJ92Meta?.path ?? "/nl",
    meta: indexq9u1LmbJ92Meta || {},
    alias: indexq9u1LmbJ92Meta?.alias || [],
    redirect: indexq9u1LmbJ92Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexq9u1LmbJ92Meta?.name ?? "index___de",
    path: indexq9u1LmbJ92Meta?.path ?? "/de",
    meta: indexq9u1LmbJ92Meta || {},
    alias: indexq9u1LmbJ92Meta?.alias || [],
    redirect: indexq9u1LmbJ92Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexq9u1LmbJ92Meta?.name ?? "index___es",
    path: indexq9u1LmbJ92Meta?.path ?? "/es",
    meta: indexq9u1LmbJ92Meta || {},
    alias: indexq9u1LmbJ92Meta?.alias || [],
    redirect: indexq9u1LmbJ92Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login2JQ1VYThUCMeta?.name ?? "login___en",
    path: login2JQ1VYThUCMeta?.path ?? "/login",
    meta: login2JQ1VYThUCMeta || {},
    alias: login2JQ1VYThUCMeta?.alias || [],
    redirect: login2JQ1VYThUCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: login2JQ1VYThUCMeta?.name ?? "login___nl",
    path: login2JQ1VYThUCMeta?.path ?? "/nl/login",
    meta: login2JQ1VYThUCMeta || {},
    alias: login2JQ1VYThUCMeta?.alias || [],
    redirect: login2JQ1VYThUCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: login2JQ1VYThUCMeta?.name ?? "login___de",
    path: login2JQ1VYThUCMeta?.path ?? "/de/login",
    meta: login2JQ1VYThUCMeta || {},
    alias: login2JQ1VYThUCMeta?.alias || [],
    redirect: login2JQ1VYThUCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: login2JQ1VYThUCMeta?.name ?? "login___es",
    path: login2JQ1VYThUCMeta?.path ?? "/es/login",
    meta: login2JQ1VYThUCMeta || {},
    alias: login2JQ1VYThUCMeta?.alias || [],
    redirect: login2JQ1VYThUCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registeraaxlU31oFBMeta?.name ?? "register___en",
    path: registeraaxlU31oFBMeta?.path ?? "/register",
    meta: registeraaxlU31oFBMeta || {},
    alias: registeraaxlU31oFBMeta?.alias || [],
    redirect: registeraaxlU31oFBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registeraaxlU31oFBMeta?.name ?? "register___nl",
    path: registeraaxlU31oFBMeta?.path ?? "/nl/register",
    meta: registeraaxlU31oFBMeta || {},
    alias: registeraaxlU31oFBMeta?.alias || [],
    redirect: registeraaxlU31oFBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registeraaxlU31oFBMeta?.name ?? "register___de",
    path: registeraaxlU31oFBMeta?.path ?? "/de/register",
    meta: registeraaxlU31oFBMeta || {},
    alias: registeraaxlU31oFBMeta?.alias || [],
    redirect: registeraaxlU31oFBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registeraaxlU31oFBMeta?.name ?? "register___es",
    path: registeraaxlU31oFBMeta?.path ?? "/es/register",
    meta: registeraaxlU31oFBMeta || {},
    alias: registeraaxlU31oFBMeta?.alias || [],
    redirect: registeraaxlU31oFBMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src1175223133/src/sense-us.app/pages/register.vue").then(m => m.default || m)
  }
]